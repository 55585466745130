<template>
  <div>
    <Modal
        v-model="modal_add_relation"
        :title="'联赛id: ' + new_relation.id"
        @on-ok="ok"
    >
        <thirdTournamentSearch :sport_id="sport_id" v-model="new_relation.third" @onSourceIdChange="newRelationSourceIdChange" ></thirdTournamentSearch>
    </Modal>

    <Modal v-model="modal_edit_unique_tournament" draggable footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑赛事</span>
        </div>
        <div class="modal-div-containter">
            <uniqueTournamentEdit :sport_id="sport_id" :unique_tournament_id="edited_unique_tournament_id" @closePage="closeEditModalUniqueTournament"></uniqueTournamentEdit>
        </div>
    </Modal>
    <Modal v-model="modal_edit_season" draggable  footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑赛季</span>
        </div>
        <div class="modal-div-containter">
            <seasonEdit :sport_id="sport_id" :season_id="edited_season_id" @closePage="closeEditModalSeason"></seasonEdit>
        </div>
    </Modal>
    
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
        :transfer="false"
    >
        <tournamentEdit :sport_id="sport_id" :tournament_id="edit_tournament_id" @closePage="closeEditPage"></tournamentEdit>
    </Drawer> 
    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="5" style="text-align: left">
            <Select v-model="category_id" @on-change='onCategoryIdChange' >
                <Option :value="0" :key="0">全部</Option>
                <Option v-for="item in cayegory_dic" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
        </Col>
        <Col span="15" style="text-align: right">
            <Button type="success" @click="openEdit" >新增</Button>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getTournament, addTournamentRelation, delTournamentRelation, getTournamentCategory } 
    from '@/api/ballsports/base/tournament';
import thirdTournamentSearch from './third_source_search.vue';
import tournamentEdit from './edit.vue';
import uniqueTournamentEdit from '../unique_tournament/edit.vue';
import seasonEdit from '../season/edit.vue';
import fitTable from '../../basic/table.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    // let query = { sport_id: vm.sport_id, tournament_id: params.row.id }
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/tennis/tournament/detail', query: query })
                    // window.open(resolve_data.href, '_blank')
                    // console.log( query );
                    vm.edit_tournament_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "tournament-list",
    props: {
        sport_id: Number,   // 运动id
        season_id: Number,  // 联赛id
    },
    components: {
        thirdTournamentSearch,
        tournamentEdit,
        uniqueTournamentEdit,
        seasonEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            modal_add_relation: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width: 100,
                },
                
                {
                    title: '名称',
                    align: 'center',
                    key: 'name_lang',
                    minWidth: 80,
                },
                {
                    title: '简称',
                    align: 'center',
                    key: 'abbr',
                    minWidth: 80,
                },
                {
                    title: '赛事',
                    align: 'left',
                    key: 'unique_tournament_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let unique_tournament = currentRow.unique_tournament 
                        return unique_tournament.id > 0?[
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editUniqueTournament(unique_tournament.id);
                                }
                            } },  unique_tournament.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editUniqueTournament(unique_tournament.id);
                                }
                            } }, unique_tournament.name,  ),
                        ]: '';
                    }
                },
                {
                    title: '赛季',
                    align: 'left',
                    key: 'season_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let season = currentRow.season; 
                        return season.id> 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editSeason(season.id);
                                }
                            } },  season.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editSeason(season.id);
                                }
                            } }, season.name,  ),
                        ]: '';
                    }
                },
                {
                    title: '分类',
                    align: 'center',
                    key: 'category',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let category_id = currentRow.category_id 
                        let category = self.cayegory_dic[category_id] || {};
                        return h('span', category.name || '')
                    }
                },
                {
                    title: '地面',
                    align: 'center',
                    key: 'ground_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let ground_id = currentRow.ground_id;
                        if(ground_id > 0){
                            let ground = self.$conf.ballsports.grounds[ground_id];
                            return ground ? h('span', ground.name):  h('span', '未知场地类型: '+ground_id);
                        }else{
                            return h('span', '')
                        }
                    }
                },
                {
                    title: '年份',
                    align: 'center',
                    key: 'year',
                    width: 80,
                },
                // {
                //     title: '关联',
                //     align: 'left',
                //     key: 'relation',
                //     minWidth: 120,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let relation = currentRow.relation || {};
                //         if (relation && relation.length > 0){
                //             var _texts =  [ ]
                //             relation.forEach((element, _index) => {
                //                 let source_name = element.source_name;
                //                 _texts.push( 
                //                     h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Poptip', {
                //                                     props: {
                //                                         placement: 'left-start',
                //                                         confirm: true,
                //                                         transfer: true,
                //                                         title: '确定删除"'+ source_name +'"吗？',
                //                                     },
                //                                     on: {
                //                                         'on-ok': () => {
                //                                             // this.deletedMind();//调用删除方法
                //                                             self.delRelation( currentRow.id , element.source_id, element.third_id);
                //                                             relation.splice(_index, 1);
                //                                         },
                //                                         'on-cancel': () => {
                //                                         }
                //                                     }
                //                                 }, 
                //                                 [h('Icon', 
                //                                     {   
                //                                         attrs: { type: "md-trash", }, 
                //                                         // on: {
                //                                         //     'click':() => {
                //                                         //         relation.splice(_index, 1);
                //                                         //     }
                //                                         // } 
                //                                     },  
                //                                 )] 
                //                             )],
                //                         ),
                //                         h('Col', { attrs: { span: 5 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-main", 
                //                                 }, 
                //                                 element.source_name + ':',
                //                             )]
                //                         ),
                //                         h('Col', { attrs: { span: 6 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-link", 
                //                                 }, 
                //                                 element.third_id,
                //                             )] 
                //                         ),
                //                         // h('Col', { attrs: { span: 10 }}, 
                //                         //     [h('span', 
                //                         //         {   
                //                         //             class: "text-assist", 
                //                         //         }, 
                //                         //         element.third_name,
                //                         //     )] 
                //                         // ),
                //                         ]
                //                     )
                //                 )
                //             });
                //         }else{
                //             var _texts = [];
                //         }
                //         _texts.push( h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Icon', 
                //                                 {   
                //                                     attrs: { type: "md-add-circle", }, 
                //                                     on: {
                //                                         'click':() => {
                //                                             // 为当前联赛新增关联
                //                                             self.add(currentRow.id);
                //                                             self.edited_relation = relation;
                //                                         }
                //                                     } 
                //                                 },  
                //                             )] 
                //                         ),
                //                         ]
                //                     ) )
                //         return _texts
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton(self, h, params), ]// delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
            new_relation: {
                id: 0, // 比赛id
                source_id: '',  // 源id
                third: {
                    id: 0,      // 赛事id
                    name: '',   // 赛事名称
                }
            },
            edited_relation: [],
            datarange : '',
            date_options: {
                shortcuts: [
                    {
                        text: '1个月',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '1周',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '6小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '3小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 3);
                            return [start, end];
                        }
                    },
                    {
                        text: '30分钟',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 1800 * 1000);
                            return [start, end];
                        }
                    },
                    {
                        text: '昨天',
                        value () {
                            const end = new Date(new Date().toLocaleDateString());
                            const start = new Date();
                            start.setTime(end.getTime() - 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '今天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            end.setTime(start.getTime() + 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '明天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            start.setTime(start.getTime() + 3600 * 1000 * 24);
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            return [start, end];
                        }
                    },
                    
                ]
            },
            cayegory_dic: {},
            category_id: 0,
            edit_tournament_id: 0,
            // 编辑
            modal_edit_unique_tournament: false,
            modal_edit_season: false,
            edited_unique_tournament_id: 0,
            edited_season_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onCategoryIdChange(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        onDatarangeChange(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        onIsCurrentChange(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        // 获取联赛分类
        getCategorys(){
            let lang = sessionStorage.getItem("lang");
            var params = {
                sport_id: this.sport_id,
                lang: lang,
            }
            getTournamentCategory(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.cayegory_dic = response.data.data;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        getData () {
            let self = this;
            this.loading = true;
            let lang = sessionStorage.getItem("lang");
            // 搜索日期
            var params = {
                sport_id: self.sport_id,
                search_key: self.search_key,
                page: self.current_page,
                season_id: self.season_id,
                category_id: self.category_id,
                lang: lang,
            }
            getTournament(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        addRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            addTournamentRelation(params).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success('添加成功');
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        delRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            delTournamentRelation(params).then(response => {
                if (response.data.code == 0) {
                    // self.$Message.success(response.data.msg);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add ( _id ) {
            this.new_relation["id"] = _id;
            this.modal_add_relation = true;
        },
        // end
        newRelationSourceIdChange(source_id, source_name){
            this.new_relation.source_id = source_id;
            this.new_relation.source_name = source_name;
        },
        ok () {
            // 为联赛新增加第三方的关联
            let self = this;
            if (self.new_relation && self.new_relation.third){
                self.addRelation(self.new_relation.id, self.new_relation.source_id, self.new_relation.third.id)
                self.edited_relation.push(
                    {
                        source_id: self.new_relation.source_id,
                        source_name: self.new_relation.source_name,
                        third_id: self.new_relation.third.id,
                        third_name: self.new_relation.third.name,
                    }
                )
            }
        },
        // delete (_id) {
        //     var params = {
        //         id: _id,
        //         type: this.data_type
        //     }
        //     deleteData(params).then(response => {
        //         if (response.data.code == 0) {
        //             return true
        //         } else {
        //             this.$Message.error(response.data.msg)
        //             return false
        //         }
        //     })
        // },
        // end 
        openEdit(){
            let self = this;
            self.edit_tournament_id=0;
            self.drawer_edit = true;
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
        },
        // end 
        closeEditModalUniqueTournament(){
            let self = this;
            self.modal_edit_unique_tournament = false;
            self.edited_unique_tournament_id = 0;
        },
        // end
        closeEditModalSeason(){
            let self = this;
            self.modal_edit_season = false;
            self.edited_season_id = 0;
        },
        // end
        editUniqueTournament(_id){
            let self = this;
            self.edited_unique_tournament_id = _id;
            self.modal_edit_unique_tournament = true;
        },
        // end
        editSeason(_id){
            let self = this;
            self.edited_season_id = _id;
            self.modal_edit_season = true;
        },
        // end
    },
    mounted () {
        if (this.season_id > 0){
            // 删除联赛id
            this.columns.splice(8, 1); // 删除"关联"这一列
        }
        this.getCategorys();
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>