import http from '@/utils/http';

// 获取
export const getTournamentTrans = (params) => {
    return http.request({
        url: '/v1/ballsports/base/tournament/trans/get',
        method: 'get',
        params: params
    })
}

// 更新
export const updateTournamentTrans = (params) => {
    return http.request({
        url: "/v1/ballsports/base/tournament/trans/update",
        method: 'post',
        data: params,
    })
}
