<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="赛事: ">
                <Row>
                    <Col span="20" >
                        <uniqueTournamentSearch v-model="data.unique_tournament" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'unique_tournament_id'] === 1? 'locked-input': '' "></uniqueTournamentSearch>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'unique_tournament_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="unique_tournament_id"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="name"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="简称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.abbr" :class="locks[String(type)+'-1-'+'abbr'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'abbr']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="abbr"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="年份: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.year" :class="locks[String(type)+'-1-'+'year'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'year']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="year"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="开始时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'start_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="start_time"
                        >
                        </DatePicker>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'start_time']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="start_time"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="结束时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'end_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="end_time"
                        >
                        </DatePicker>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'end_time']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="end_time"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="是否中立: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.neutral_ground" :class="locks[String(type) + '-1-' + 'neutral_ground'] === 1? 'locked-select': '' ">
                            <Option :value="0" :key="0">否</Option>
                            <Option :value="1" :key="1">是</Option>
                        </Select>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-' + 'neutral_ground']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="neutral_ground"></Lock>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="当前赛季: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.is_current" :class="locks[String(type) + '-1-' + 'is_current'] === 1? 'locked-select': '' ">
                            <Option :value="0" :key="0">否</Option>
                            <Option :value="1" :key="1">是</Option>
                        </Select>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-' + 'is_current']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="is_current"></Lock>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="是否删除: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                            <Option :value="1" key="1">是</Option>
                            <Option :value="0" key="0">否</Option>
                        </Select>
                    </Col>
                    <Col span="2" offset='1'>
                        <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="season_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import uniqueTournamentSearch from '../unique_tournament/search.vue';
import Lock from '../lock.vue';
import { getSeason, updateSeason } from '@/api/ballsports/base/season';
import moment from 'moment';

export default {
    name: "season-edit",
    props: {
        sport_id: Number,   // 运动id
        season_id: Number,   // 联赛id
    },
    components: {
        Lock,
        uniqueTournamentSearch
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 3,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                start_time: parseInt( moment() / 1000 ),
                end_time: parseInt( moment() / 1000),
                unique_tournament:{},
                deleted: 0,
                sport_id: self.sport_id,
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.season_id && self.season_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.season_id,
                }
                getSeason( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks ;
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
            }
            
        },
        // end 
        emit(){
            this.$emit('closePage')
        },

        save() {
            let self = this;
            // console.log(self.data)
            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                unique_tournament_id: self.data.unique_tournament.id,
                name: self.data.name,
                abbr: self.data.abbr,
                year: self.data.year,
                start_time: self.data.start_time,
                end_time: self.data.end_time,
                neutral_ground: self.data.neutral_ground,
                is_current: self.data.is_current,
                deleted: self.data.deleted,
            };
            
            updateSeason({params}).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit()
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        let self = this;
        // self.getData();
    },
    watch: {
        'season_id': {
            handler(value){
                // console.log("Detail Get Data", value);
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
        start_time: {
            get: function () {
                return moment.unix(this.data.start_time).toDate();
            },
            set: function (newValue) {
                this.data.start_time = moment(newValue).unix();
            }
        },
        end_time: {
            get: function () {
                return moment.unix(this.data.end_time).toDate();
            },
            set: function (newValue) {
                this.data.end_time = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>