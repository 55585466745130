<template>
    <div>
        <Drawer
            v-model="drawer_trans"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <tournamentTransEdit :sport_id="sport_id" :tournament_id="trans_id" @closePage="closeTransPage"></tournamentTransEdit>
        </Drawer> 

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="name"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="简称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.abbr" :class="locks[String(type)+'-1-'+'abbr'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'abbr']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="abbr"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="赛事: ">
                    <Row>
                        <Col span="20" >
                            <uniqueTournamentSearch :sport_id="sport_id" v-model="data.unique_tournament" :classname="locks[String(type)+'-1-'+'unique_tournament_id'] === 1? 'locked-input': '' "></uniqueTournamentSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'unique_tournament_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="unique_tournament_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="赛季: ">
                    <Row>
                        <Col span="20" >
                            <seasonSearch :sport_id="sport_id" v-model="data.season" :classname="locks[String(type)+'-1-'+'season_id'] === 1? 'locked-input': '' "></seasonSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'season_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="season_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="年份: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.year" :class="locks[String(type)+'-1-'+'year'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'year']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="year"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="分类: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.category_id" :class="locks[String(type) + '-1-' + 'category_id'] === 1? 'locked-select': '' ">
                                <Option v-for="item in category_dic" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-' + 'category_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="category_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="地面: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.ground_id" :class="locks[String(type) + '-1-' + 'ground_id'] === 1? 'locked-select': '' ">
                                <Option v-for="item in $conf.ballsports.grounds" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-' + 'ground_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="ground_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="中文简体: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_zh" :class="locks[String(type)+'-1-'+'name_zh'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name_zh']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="name_zh"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="中文繁体: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_zht" :class="locks[String(type)+'-1-'+'name_zht'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name_zht']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="name_zht"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="英文: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_en" :class="locks[String(type)+'-1-'+'name_en'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name_en']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="name_en"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="全称翻译: ">
                    <Row>
                        <Col span="20" >
                            <Button type="default" long @click="editTrans">翻译</Button>
                        </Col>
                    </Row>
                </FormItem>

            </Form>
            </TabPane>
            <TabPane label="额外信息" name="2">
                <Form label-position="left" :label-width="80">
                    <FormItem label="类型: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.tennis_info.type" :class="locks[String(type) + '-2-' + 'type'] === 1? 'locked-select': '' ">
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option :value="1" :key="1">单打</Option>
                                    <Option :value="2" :key="2">双打</Option>
                                    <Option :value="3" :key="3">混双</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'type']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="type"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="资格赛: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="qualification" :class="locks[String(type) + '-2-' + 'qualification'] === 1? 'locked-select': '' ">
                                    <Option :value="0" :key="0">否</Option>
                                    <Option :value="1" :key="1">是</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'qualification']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="qualification"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="开始时间: " >
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    :class="locks[String(type) + '-2-' + 'start'] === 1? 'locked-input': '' "
                                    style="width: 100%"
                                    v-model="start_time"
                                >
                                </DatePicker>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'start']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="start"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="结束时间: " >
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    :class="locks[String(type) + '-2-' + 'end'] === 1? 'locked-input': '' "
                                    style="width: 100%"
                                    v-model="end_time"
                                >
                                </DatePicker>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'end']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="end"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="奖金单位: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.tennis_info.prize_currency" :class="locks[String(type)+'-2-'+'prize_currency'] === 1? 'locked-input': '' "></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'prize_currency']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="prize_currency"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="奖金(数字): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.tennis_info.prize" :class="locks[String(type)+'-2-'+'prize'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'prize']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="prize"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="城市名称: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.tennis_info.city" :class="locks[String(type)+'-2-'+'city'] === 1? 'locked-input': '' " ></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'city']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="city"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="盘数: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.tennis_info.sets" :class="locks[String(type)+'-2-'+'sets'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'sets']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="sets"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="性别: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.tennis_info.gender" :class="locks[String(type) + '-2-' + 'gender'] === 1? 'locked-select': '' ">
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option :value="1" :key="1">男</Option>
                                    <Option :value="2" :key="2">女</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'gender']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="gender"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="参赛选手数量: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.tennis_info.competitors" :class="locks[String(type)+'-2-'+'competitors'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'competitors']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="competitors"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <!-- <FormItem label="国家: ">
                        <Row>
                            <Col span="20" >
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'country_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="tournament_id" :type="type" :sub_type="2" _key="country_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem> -->
                </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import uniqueTournamentSearch from '../unique_tournament/search.vue';
import seasonSearch from '../season/search.vue';
import Lock from '../lock.vue';
import { getTournament, getTournamentCategory, updateTournament } from '@/api/ballsports/base/tournament';
import moment from 'moment';
import tournamentTransEdit from './trans/edit.vue';

export default {
    name: "tournament-edit",
    props: {
        sport_id: Number,   // 运动id
        tournament_id: Number,   // 联赛id
    },
    components: {
        Lock,
        uniqueTournamentSearch,
        seasonSearch,
        tournamentTransEdit
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 3,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                name:'',
                abbr:'',
                category_id: 0,
                ground_id: 0,
                relation: [],
                season: {},
                unique_tournament: {},
                deleted: 0,
                tennis_info: {},
            },
            locks: {},
            category_dic: {},
            drawer_trans: false,
            trans_id: 0,
        }
    },
    methods: {
        // 获取联赛分类
        getCategorys(){
            var params = {
                sport_id: this.sport_id,
            }
            getTournamentCategory(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.category_dic = response.data.data;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        getData () {
            let self = this;
            if (self.tournament_id && self.tournament_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.tournament_id,
                }
                getTournament( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks ;
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                // self.data = {
                //     id: 0,
                //     sport_id: self.sport_id,
                //     name:'',
                //     abbr:'',
                //     category_id: 0,
                //     ground_id: 0,
                //     relation: [],
                //     season: {},
                //     unique_tournament: {},
                //     deleted: 0,
                //     tennis_info: {},
                // }
                self.locks = {}
            }
            
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
            var params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                unique_tournament_id: self.data.unique_tournament.id,
                season_id: self.data.season.id,
                category_id: self.data.category_id,
                name: self.data.name,
                abbr: self.data.abbr,
                ground_id: self.data.ground_id,
                year: self.data.year,
                tennis_info: self.data.tennis_info,
                deleted: self.data.deleted,
                name_zh : self.data.name_zh,
                name_zht: self.data.name_zht,
                name_en : self.data.name_en,
            };
            updateTournament(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        editTrans(){
            let self = this;
            self.drawer_trans = true;
            self.trans_id = self.tournament_id;
        },
        // end
        closeTransPage(){
            let self = this;
            self.drawer_trans = false;
            self.trans_id = 0;
        },
        // end

    },
    mounted () {
        let self = this;
        self.getCategorys();
        // self.getData();
    },
    watch: {
        "tournament_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
        start_time: {
            get: function () {
                return moment.unix(this.data.tennis_info.start).toDate();
            },
            set: function (newValue) {
                this.data.tennis_info.start = moment(newValue).unix();
            }
        },
        end_time: {
            get: function () {
                return moment.unix(this.data.tennis_info.end).toDate();
            },
            set: function (newValue) {
                this.data.tennis_info.end = moment(newValue).unix();
            }
        },
        qualification: {
            get: function () {
                return this.data.tennis_info.qualification? 1: 0;
            },
            set: function (newValue) {
                this.data.tennis_info.qualification = newValue===1?true: false;
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>